<template>
  <div class="rewards">
    <feed-detail-banner
      title="Read offer"
      :is-public="isPublic"
    />
    <div class="container">
      <el-row :gutter="0">
        <el-col
          :xl="17"
          :lg="17"
          :md="24"
          :sm="24"
          :xs="24"
        >
          <div class="left-side">
            <div
              v-show="loading"
              v-loading="loading"
              :class="{ loading: loading }"
            ></div>

            <div v-if="reward">
              <h1 class="title">
                {{ reward.title }}
              </h1>
              <div class="reward-content">
                <div
                  v-if="reward.mediaFile.is_image"
                  class="media"
                >
                  <img
                    :src="reward.mediaFile.large_thumb"
                    alt=""
                  />
                </div>
                <video
                  v-else
                  width="100%"
                  controls
                >
                  <source :src="reward.mediaFile.path" />
                  Your browser does not support the video tag.
                </video>
                <div class="desc">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <p v-html="reward.description"></p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col
          :xl="7"
          :lg="7"
          class="hidden-md-and-down"
        >
          <sidebar
            v-if="reward"
            :feed-key="reward.feed_key"
            :is-public="isPublic"
            :is-related="true"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import feedApi from '@/modules/feed/services/feed.api'
import accountApi from '@/modules/account/services/account.api'
import FeedDetailBanner from '@/modules/feed/components/feed-detail-banner'
import Sidebar from '@/modules/feed/components/sidebar'

export default {
  components: {
    FeedDetailBanner,
    Sidebar,
  },

  props: {
    isPublic: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    loading: false,
    reward: null
  }),

  watch: {
    '$route.params.id' (id) {
      this.reward = null
      this.fetchReward()
    }
  },

  created() {
    this.fetchReward()
  },

  methods: {
    fetchReward() {
      this.loading = true
      feedApi.reward(this.$route.params.id, this.isPublic)
        .then(response => {
          this.loading = false
          this.reward = response.data
          if (!this.isPublic) {
            this.updatePoints()
          }
        }).catch(() => {
          this.$router.push({ name: 'feed-feed' })
        })
    },
    updatePoints() {
      accountApi
        .gamers()
        .then(response => {
          this.$store.commit("UPDATE_POINTS", response.data);
        })
    },
  }
}
</script>

<style lang="scss">
.rewards {
  @media (max-width: 991px) {
    overflow: hidden;
  }
  .panel {
    height: 55px;
    background-color: $feed-detail-banner-bg-color;
    width: 100%;
    .container {
      position: relative;
      .text-left {
        position: absolute;
        line-height: 55px;
      }
      .text-center {
        display: inline-block;
        line-height: 58px;
        width: 100%;
      }
    }
  }
  .left-side {
    //min-height: 1350px;
    position: relative;
    background-color: $white-color;
    padding: 0 65px 42px 0;
    &:before {
      content: "";
      position: absolute;
      background-color: #fff;
      left: -90%;
      width: 90%;
      height: 100%;
      @media (max-width: 991px) {
        content: "";
        position: absolute;
        background-color: #fff;
        left: -20%;
        width: 140%;
        height: 100%;
        z-index: -1;
      }
    }
    @media (max-width: 991px) {
      padding: 0;
    }
    .loading {
      min-height: 300px;
    }
    .title {
      margin-top: 0;
      padding-top: 30px;
      @media (max-width: 575px) {
        font-size: 36px;
        line-height: 40px;
      }
      @media (max-width: 440px) {
        font-size: 24px;
      }
    }
    .point-count {
      padding: 14px 0 12px 0;
      border-top: 1px solid;
      border-bottom: 1px solid;
      border-color: $border-color;
      .star-points{
        font-size: 16px;
        color: $primary-color;
      }
      .point-title {
        font-size: 13px;
        margin-left: 6px;
      }
    }
    .reward-content {
      margin-top: 20px;
      .desc {
        img {
          width: 100% !important;
        }
      }
      .media {
        height: 372px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
    }
    .desc {
      margin: 20px 0 50px 0;
      p {
        margin: 0 auto 30px auto;
      }
    }
  }
}
</style>
